.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.row-accept {
  background-color: #dff0d8;
}

.row-pending {
  background-color: rgb(216, 216, 54);
}

.row-decline {
  background-color: #f2dede;
}

.blink {
  animation: blink-animation 1s infinite;

}

.blink1 {
  animation: blink1-animation 1s infinite;
}

@keyframes blink-animation {
  0%, 100% {
    background-color: #f7ea79;
  }
  50% {
    background-color: transparent; 
  }
}

@keyframes blink1-animation {
  0%, 100% {
    background-color: #7591E6;
  }
  50% {
    background-color: transparent; 
  }
}

.row-clockin-pending {
  background-color: rgb(216, 216, 54);
}

.row-clockout-pending {
  background-color: rgb(240, 180, 68);
}
