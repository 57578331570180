#header {
  padding-inline-start: 0 !important;
  padding-inline-end: 20px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 50;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  -webkit-box-shadow: 0px 27px 48px -30px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 27px 48px -30px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 27px 48px -30px rgba(0, 0, 0, 0.6);
}

.ant-layout-sider,
.ant-menu-light {
  overflow-x: hidden;
  min-width: max-content !important;
  max-width: max-content !important;
  width: max-content !important;
  background-color: white;
}

.ant-layout-sider-collapsed {
  min-width: 0px !important;
  max-width: 0px !important;
  width: 0px !important;
}

.notification:hover {
  cursor: pointer;
}
