/* Root font-size on the document level */
html {
  font-size: 14px;
}

@media (max-width: 900px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 10px;
  }
}

.placement-form {
  max-width: 60vw;
  margin: -4% 20%;
}

.proceedToPayment {
  padding: 0.3rem 3rem;
}

.custom-course-select .ant-select-selection-item {
  color: #6c559e !important;
  font-weight: bold !important;
}

/* For active state */
.custom-slider .ant-slider-track {
  background-color: green !important;
}

.custom-slider .ant-slider-handle {
  border-color: green !important;
  background-color: green !important; 
}

.custom-slider .ant-slider-handle::after {
  background-color: green !important;
}

.custom-slider .ant-slider-rail {
  background-color: #d9d9d9 !important; 
}

/* For disabled state */
.custom-slider.ant-slider-disabled .ant-slider-track {
  background-color: green !important;
}

.custom-slider.ant-slider-disabled .ant-slider-handle {
  border-color: green !important;
  background-color: green !important; 
}

.custom-slider.ant-slider-disabled .ant-slider-handle::after {
  background-color: green !important;
}

.signature {
  display: flex;
}

.signature-button {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 10px;
}

@media (max-width: 350px) {
  .ant-select-selection-item {
    font-size: 1rem;
  }
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-group-title,
  .ant-select-item-option-content {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 351px) and (max-width: 768px) {
  .ant-select-selection-item {
    font-size: 1.1rem;
  }
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-group-title,
  .ant-select-item-option-content {
    font-size: 1.1rem;
  }
  .signature {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signature-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .ant-select-selection-item {
    font-size: 1rem;
  }
} 

@media only screen and (min-width: 992px) and (max-width: 1014px) {
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-group-title,
  .ant-select-item-option-content {
    font-size: 1rem;
  }
} 

@media only screen and (max-width: 280px) {
  .ant-btn.ant-btn-lg {
    font-size: 12px;
  }  
}

@media only screen and (max-width: 1919px) {
  .placement-form {
    max-width: 85vw;
    margin: -4% 8%;
  }
}

@media only screen and (max-width: 430px) {
  .placement-form {
    max-width: 80vw;
    margin: -4% 10%;
  }
}

@media only screen and (min-width: 455px) and (max-width: 539px) {
  .survey-fill-model {
    width: 75vw;
  }
}

@media only screen and (min-width: 540px) and (max-width: 630px) {
  .survey-fill-model {
    width: 70vw;
  }
}

@media only screen and (min-width: 631px) and (max-width: 767px) {
  .survey-fill-model {
    width: 60vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 911px) {
  .survey-fill-model {
    width: 50vw;
  }
}

@media only screen and (min-width: 912px) and (max-width: 1023px) {
  .survey-fill-model {
    width: 45vw;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1150px) {
  .survey-fill-model {
    width: 35vw;
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1250px) {
  .survey-fill-model {
    width: 30vw;
  }
}

@media only screen and (width: 1280px) {
  .survey-fill-model {
    width: 30vw;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5f5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.searchFilter {
  width: 100%;
  justify-content: flex-end;
  margin: 1rem 0;
}

.addButton {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.custom-input::placeholder {
  color: #3f3e3e;
  font-style: italic;
  font-size: 14px;
}

.custom-input .ant-select-selection-placeholder {
  color: #3f3e3e; 
  font-style: italic; 
  font-size: 14px;
}

.custom-input .ant-picker-input > input::placeholder {
  color: #3f3e3e; 
  font-style: italic; 
  font-size: 14px;
}
